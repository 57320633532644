<template>
    <div id="add-goods">
        <!--    添加商品成功    -->
        <h1 class="result-msg" v-if="addSuccess && !isInit">商品添加成功</h1>
        <div class="goods-info-box" v-if="addSuccess && !isInit">
            <img class="cover" v-lazy="goodsInfo['product_cover']" alt=""/>
            <div class="title">{{goodsInfo['product_name']}}</div>
            <div class="info-box">
                <div class="basic-info-box">
                    <span>规格：{{goodsInfo['specifications']}}</span>
                    <span class="code">单位：{{goodsInfo['unit']}}</span>
                    <span class="code">分类：{{goodsInfo['category_name']}}</span>
                    <span class="code">条码：{{lastSubmitBarCode?lastSubmitBarCode:'-'}}</span>
                </div>
            </div>
        </div>
        <!--    添加商品失败    -->
        <h1 class="result-msg" v-if="!addSuccess && !isInit">{{resultMsg}}</h1>
        <div class="goods-info-box" v-if="!addSuccess && !isInit">
            <div class="last-bar-code">{{lastSubmitBarCode}}</div>
        </div>
        <!--    扫码按钮    -->
        <div class="add-btn" @click="scanCode()">{{isInit?'扫码添加商品':'继续添加'}}</div>
        <!--    前去查询录入商品    -->
        <div v-if="!addSuccess && !isInit" class="add-btn" @click="toCollector()">前去查询录入商品</div>
        <!--    确认结果提示框    -->
        <van-dialog class="barcode-dialog"
                    v-model="show" title="确认扫码结果"
                    show-cancel-button
                    confirm-button-text="录入"
                    cancel-button-text="取消"
                    @confirm="submitCode()">
            <label>
                <input class="barcode" v-model="currentBarCode" type="text" placeholder="请填写条形码"/>
            </label>
        </van-dialog>
    </div>
</template>

<script>
    import wxShare from "../common/js/wxShare";
    import {submitBarCodeToAddGoodsForShop} from "../api/requist";
    import {eventBus} from "../common/js/bus";
    import {Toast} from "vant";
    import router from "../router";

    export default {
        name: "addGoods",
        data() {
            return {
                resultMsg: '查无此商品信息，请联系管理员添加',
                isInit: true,
                addSuccess: false,
                show: false,
                shopInfo: {},
                currentBarCode: '',
                goodsInfo: {},
                lastSubmitBarCode: ''
            }
        },
        created() {
            eventBus.$on('scanSuccess', (result) => {
                this.scanCallback(result);
            });
            eventBus.$on('scanFail', () => {
                Toast.fail('扫码器开启失败');
            });
        },
        mounted() {
            this.shopInfo = this.$route.query.shopInfo
        },
        methods: {
            // 前去录入商品
            toCollector: function () {
                router.push({
                    path: '/codesCollector'
                })
            },
            // 扫码
            scanCode: function () {
                Toast.loading({
                    message: '正在开启扫码器',
                    forbidClick: true,
                });
                wxShare.onScanCodeToAddGoodsForShop();
            },

            // 扫码结果回调
            scanCallback: function (res) {
                Toast.clear();
                let split = res.split(',');
                this.currentBarCode = split[1];
                this.show = true;
            },

            // 提交扫码结果
            submitCode: function () {
                Toast.loading({
                    message: '正在提交...',
                    forbidClick: true,
                });
                this.isInit = false;
                this.lastSubmitBarCode = this.currentBarCode;
                let formData = new FormData();
                formData.append('merch_id', this.shopInfo.id);
                formData.append('barcode', this.currentBarCode);
                submitBarCodeToAddGoodsForShop(formData).then((res) => {
                    Toast.clear();
                    if (res.code === 200) {
                        Toast.success('添加成功');
                        this.addSuccess = true;
                        this.goodsInfo = res.data;
                    } else if (res.code === 402) {
                        Toast.fail('添加失败');
                        this.addSuccess = false;
                        this.resultMsg = "查无此商品信息，请联系管理员添加";
                    } else {
                        Toast.fail('添加失败');
                        this.addSuccess = false;
                        this.resultMsg = res.message;
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    #add-goods {
        background-color: #F5F7FA;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .result-msg {
            flex: 0 66px;
            color: #303133;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            padding-top: 22px;
            padding-bottom: 22px;
            box-sizing: border-box;
        }

        .goods-info-box {
            background-color: white;
            width: calc(100vw - 32px);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 16px 32px 16px;
            border-radius: 4px;
            padding: 12px 16px;
            box-sizing: border-box;

            .last-bar-code {
                width: 100%;
                font-size: 16px;
                font-weight: bold;
                color: #303133;
                text-align: center;
                letter-spacing: 3px;
            }

            .cover {
                flex: 0 80px;
                width: 80px;
                height: 80px;
                object-fit: cover;
            }

            .title {
                width: 100%;
                margin-top: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                line-height: 20px;
                color: #303133;
                white-space: nowrap;
            }

            .info-box {
                width: 100%;
                margin-top: 16px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .basic-info-box {
                    font-size: 12px;
                    color: #909399;
                    display: flex;
                    flex-direction: column;

                    .code {
                        margin-top: 8px;
                    }
                }
            }
        }

        .add-btn {
            width: calc(100vw - 32px);
            background-color: #FF6737;
            color: white;
            font-size: 14px;
            line-height: 18px;
            padding: 12px 0;
            text-align: center;
            border-radius: 4px;
            margin: 16px 16px 0 16px;
        }

        .van-dialog {
            border-radius: 4px;
        }

        .barcode-dialog {
            text-align: center;

            .van-dialog__content {
                padding: 0 16px;

                .barcode {
                    width: 100%;
                    font-size: 14px;
                    line-height: 18px;
                    margin: 24px 0;
                    padding: 9px 12px;
                    box-sizing: border-box;
                    color: #303133;
                    border-radius: 4px;
                    background-color: #f5f7fa;
                    border: solid 1px #f2f6fc;
                }
            }
        }
    }
</style>
